import React from 'react';
import {TextInputWrappper, InputWrappper} from './TextInputStyled';
import { useField } from 'formik';
import {FiSearch} from 'react-icons/fi';
import { Error } from '../Error';

export const TextInput = ({placeholder,icon,fullWidth , handleChange, customHandle, name, ...props}) => {
    const meta = useField(name)[1];

    return (
        <TextInputWrappper fullWidth={fullWidth}>
            <InputWrappper
            >
                { icon && 
                <span><FiSearch/></span>
                }
                <input 
                    type="text" 
                    name={name}
                    placeholder={placeholder}
                    onChange={customHandle? (e)=>customHandle(e.target.value) : handleChange}
                />
            </InputWrappper>
            <Error meta={meta} />
        </TextInputWrappper>
    );
};
